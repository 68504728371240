
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppAdministratorOrganizationsRootListIndex} from "@/models/app/administrator/organizations/root/list/index";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import {InfoFilled} from "@element-plus/icons-vue";
import AppAdministratorOrganizationsRootListFormCreateIndexVue from "@/views/app/administrator/organizations/root/list/form/create.vue";
import AppAdministratorOrganizationsRootListFormRemoveIndexVue from "@/views/app/administrator/organizations/root/list/form/remove.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    InfoFilled,
    AppAdministratorOrganizationsRootListFormCreateIndexVue,
    AppAdministratorOrganizationsRootListFormRemoveIndexVue,
  },
})
export default class AppAdministratorOrganizationsRootListIndexVue extends Vue {
  isLoading: boolean | null = false;

  organizations: Array<AppAdministratorOrganizationsRootListIndex> = [];

  isRemoveOrganizationDisabled(id: string): boolean {
    return store.getters.getOrganizationMember.organization_id === id;
  }

  async getUserOrganizationsForAdministration(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/user/organizations-for-administration`,
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.organizations = r.data.organizations;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    await this.getUserOrganizationsForAdministration();
  }
}
